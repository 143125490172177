import { useState, useRef, useEffect } from "react";
import { PopupButton } from "../../../../../components/popup-button/popup-button";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import { Select } from "../../../../../components/select/select";
import { Input } from "../../../../../components/input/input";
import AvatarEditor from "react-avatar-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Textarea } from "../../../../../components/textarea/textarea";
import "./edit-content-popup.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { languagesList } from "../../../../../components/languages-list/languages-list";

//redux
import {
  deleteContentImage,
  editContent,
  getContentById,
  getContents,
  getCountryById,
} from "../../../../../store/media-center/media-center-slice";
import { getCategoryById } from "../../../../../store/media-center/media-center-slice";
import { useDispatch } from "react-redux";
import { NativeEvents } from "../../../../../utils/enums";
import { showToastMessage } from "../../../../../store/app-settings/app-settings-slice";
import { toast } from "react-toastify";

export const EditContentPopup = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");
  const contentLocationOptions = [
    { value: "MediaSpace", label: t("LocationOptions.MediaSpace") },
    { value: "Courses", label: t("LocationOptions.Courses") },
  ];
  const uploadFromDeviceImageRef = useRef();
  const processedImageRef = useRef();

  const [contentId, setContentId] = useState("");
  const [contentType, setContentType] = useState({ value: "", label: "" });
  const [contentLocation, setContentLocation] = useState({
    value: "MediaSpace",
    label: t("LocationOptions.MediaSpace"),
  });
  const [contentVideoType, setContentVideoType] = useState({
    value: "",
    label: "",
  });
  const [contentTitle, setContentTitle] = useState("");
  const [contentDescription, setContentDescription] = useState("");
  const [contentLink, setContentLink] = useState("");
  const [contentImage, setContentImage] = useState("");

  const [contentCountries, setContentCountries] = useState([]);
  const [contentCountriesCodes, setContentCountriesCodes] = useState([]);
  const [allContentCountries, setAllContentCountries] = useState(false);

  const [contentCategories, setContentCategories] = useState([]);
  const [contentCategoriesId, setContentCategoriesId] = useState([]);
  const [allContentCategories, setAllContentCategories] = useState(false);

  const [contentTags, setContentTags] = useState([]);
  const [contentTagsValues, setContentTagsValues] = useState([]);
  const [allContentTags, setAllContentTags] = useState(false);

  const [contentLanguages, setContentLanguages] = useState([]);
  const [contentLanguagesValues, setContentLanguagesValues] = useState([]);
  const [allContentLanguages, setAllContentLanguages] = useState(false);

  const [scale, setScale] = useState(1);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    dispatch(getContentById(props.id)).then((res) => {
      const data = res.payload;
      setContentId(data._id);
      setContentType({
        value: data.Type,
        label: t(`MediaOptions.${data.Type.toLowerCase()}`),
      });
      setContentVideoType(
        data.VideoType
          ? {
              value: data.VideoType,
              label: t(`VideoOptions.${data.VideoType}`),
            }
          : { value: "", label: "" }
      );
      setContentLocation(
        data.Location
          ? contentLocationOptions.find((x) => x.value === data.Location)
          : {
              value: "",
              label: "",
            }
      );
      setContentTitle(data.Title);
      setContentDescription(data.Description);
      setContentLink(data.Link);

      setContentImage(
        data.Image
          ? data.Image ===
            "https://tlechaim-static.fra1.digitaloceanspaces.com/gallery-images/MediaCenter/content-default.png"
            ? data.Image
            : process.env.REACT_APP_BASE_IMAGE_URL + "/" + data.Image
          : ""
      );

      setContentTagsValues(
        data.Tags.Tags.map((tag) => ({
          value: tag,
          label: t("OnBoarding:Community.Options." + tag),
        }))
      );
      setContentTags(data.Tags.Tags);

      for (const category of data.Categories.Categories) {
        dispatch(getCategoryById(category)).then((res) => {
          setContentCategories((prevState) => [...prevState, res.payload.Name]);
          setContentCategoriesId((prevState) => [
            ...prevState,
            { value: category, label: res.payload.Name },
          ]);
          setFilteredCategoriesOptions((prevState) =>
            prevState.filter((x) => x.value.toString() !== category.toString())
          );
        });
      }

      for (const country of data.Countries.Countries) {
        dispatch(getCountryById(country)).then((res) => {
          setContentCountries((prevState) => [...prevState, res.payload.Name]);
          setContentCountriesCodes((prevState) => [
            ...prevState,
            { value: res.payload.Code, label: res.payload.Name },
          ]);
          setFilteredCountriesOptions((prevState) =>
            prevState.filter(
              (x) => x.value.toString() !== res.payload.Code.toString()
            )
          );
        });
      }
      console.log({ data });
      setContentLanguagesValues(
        data.Languages.Languages.map((lang) => ({
          value: lang,
          label: languagesList.find((tempLang) => tempLang.value === lang)
            .label,
        }))
      );
      setContentLanguages(
        data.Languages.Languages.map(
          (lang) =>
            languagesList.find((tempLang) => tempLang.value === lang).label
        )
      );
    });
  }, []);

  useEffect(() => {
    document.addEventListener(
      "message",
      checkCameraAndMediaLibraryPermissionResponse
    );

    return () => {
      document.removeEventListener(
        "message",
        checkCameraAndMediaLibraryPermissionResponse
      );
    };
  }, []);

  const [
    cameraAndMediaLibraryPermissionGranted,
    setCameraAndMediaLibraryPermissionGranted,
  ] = useState(false);

  const checkCameraAndMediaLibraryPermissionResponse = (message) => {
    if (message.data.cameraAndMediaLibraryPermissionGranted) {
      setCameraAndMediaLibraryPermissionGranted(true);
      uploadFromDeviceImageRef.current.click();
    }
  };

  const dispatch = useDispatch();

  const videoOptions = [
    { value: "Vimeo", label: t("VideoOptions.vimeo") },
    { value: "YouTube", label: t("VideoOptions.youtube") },
    { value: "Iframe", label: "Iframe" },
  ];

  const videoOptionsNotVideo = [
    { value: "notVideo", label: t("VideoOptions.notVideo") },
    { value: "Vimeo", label: t("VideoOptions.Vimeo") },
    { value: "YouTube", label: t("VideoOptions.YouTube") },
    { value: "Iframe", label: "Iframe" },
  ];

  const countriesOptions = props.countries.map((country) => ({
    value: country.Code,
    label: country.Name,
  }));
  const [filteredCountriesOptions, setFilteredCountriesOptions] =
    useState(countriesOptions);

  const categoriesOptions = props.categories.map((category) => ({
    value: category.Id,
    label: category.Name,
  }));
  const [filteredCategoriesOptions, setFilteredCategoriesOptions] =
    useState(categoriesOptions);

  const tagsOptions = props.tags.map((tag) => ({
    value: tag.value,
    label: tag.label,
  }));
  const [filteredTagsOptions, setFilteredTagsOptions] = useState(tagsOptions);

  const languagesOptions = languagesList;
  const [filteredLanguagesOptions, setFilteredLanguagesOptions] =
    useState(languagesOptions);

  const changeImage = async (e) => {
    let files = e.target.files;
    let value = "";
    if (files.length !== 0) {
      setContentImage(URL.createObjectURL(files[0]));
    }
  };

  const selectAllCountries = () => {
    setContentCountries([]);
    setContentCountriesCodes([]);
    for (const countryOption of countriesOptions) {
      setContentCountries((prevState) => [...prevState, countryOption.label]);
      setContentCountriesCodes((prevState) => [...prevState, countryOption]);
    }
    setFilteredCountriesOptions([]);
    setAllContentCountries(true);
  };

  const selectAllCategories = () => {
    setContentCategories([]);
    setContentCategoriesId([]);
    for (const categoryOption of categoriesOptions) {
      setContentCategories((prevState) => [...prevState, categoryOption.label]);
      setContentCategoriesId((prevState) => [...prevState, categoryOption]);
      setFilteredCategoriesOptions([]);
      setAllContentCategories(true);
    }
  };

  const selectAllTags = () => {
    setContentCategories([]);
    setContentCategoriesId([]);
    for (const tagOption of tagsOptions) {
      setContentTags((prevState) => [...prevState, tagOption.label]);
      setContentTagsValues((prevState) => [...prevState, tagOption]);
      setAllContentTags(true);
    }
    setFilteredTagsOptions([]);
    setAllContentTags(true);
  };

  const selectAllLanguages = () => {
    setContentLanguages([]);
    setContentLanguagesValues([]);
    for (const languageOption of languagesOptions) {
      setContentLanguages((prevState) => [...prevState, languageOption.label]);
      setContentLanguagesValues((prevState) => [...prevState, languageOption]);
    }
    setFilteredLanguagesOptions([]);
    setAllContentLanguages(true);
  };

  const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const checkViableLink = () => {
    if (contentType.value === "Tip") {
      return true;
    }
    const checkYouTube =
      /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v\=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
    const checkVimeo =
      /(?:<iframe [^>]*src=")?(https?:\/\/(?:[\w]+\.)*vimeo\.com(?:[\/\w:]*(?:\/videos)?)?\/([0-9]+)[^\s"]*)"?(?:[^>]*><\/iframe>)?(?:<p>.*<\/p>)?/g;
    const checkLink =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    if (contentVideoType.value === "YouTube") {
      return checkYouTube.test(contentLink);
    } else if (contentVideoType.value === "Vimeo") {
      return checkVimeo.test(contentLink);
    } else {
      return contentLink.match(checkLink);
    }
  };

  return (
    <div className="edit-content-container flex-popup-container">
      <Popup
        height="600px"
        width="400px"
        close={() => {
          props.closePopup([], false);
          setContentType({ value: "", label: "" });
          setContentVideoType("");
          setContentTitle("");
          setContentDescription("");
          setContentLink("");
          setContentCountries([]);
          setContentCategories([]);
          setContentTags([]);
          setFilteredCountriesOptions([]);
          setErrors([]);
        }}
      >
        <div className="content-info">
          <div className="create-content-title">
            <PopupTitle>{t("edit-content-popup-title")}</PopupTitle>
          </div>
          <div className="create-input select-content-type-container">
            <Select
              style={{ width: "265px" }}
              name={"ContentLocation"}
              value={contentLocation.label}
              onChange={(e) => {
                setContentLocation(e);
              }}
              options={contentLocationOptions}
              placeholder={t("CreateContentPopup.placeholders.location")}
            />
          </div>
          {errors.includes("no-location") && (
            <div className="error-container">Location Is Required</div>
          )}
          {contentType === "Video" ? (
            <div className="create-input select-content-type-container">
              <Select
                style={{ width: "265px" }}
                name={"ContentVideoType"}
                value={contentVideoType.label}
                onChange={(e) => {
                  setContentVideoType(e);
                }}
                options={videoOptions}
                placeholder={t("CreateContentPopup.placeholders.video-type")}
              />
            </div>
          ) : (
            <div className="create-input select-content-type-container">
              <Select
                style={{ width: "265px" }}
                name={"ContentVideoType"}
                value={contentVideoType.label}
                onChange={(e) => {
                  setContentVideoType(e);
                }}
                options={videoOptionsNotVideo}
                placeholder={t("CreateContentPopup.placeholders.video-type")}
              />
            </div>
          )}
          {errors.includes("no-video-type") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.video-type")}
            </div>
          )}
          <div className="create-input content-title-container">
            <Textarea
              type="text"
              value={contentTitle}
              width="265px"
              onChange={(e) => {
                setContentTitle(e.target.value);
              }}
              placeholder={t("CreateContentPopup.placeholders.title")}
            />
          </div>
          {errors.includes("no-title") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.title")}
            </div>
          )}
          <div className="create-input content-description-container">
            <Textarea
              type="text"
              value={contentDescription}
              width="265px"
              onChange={(e) => {
                setContentDescription(e.target.value);
              }}
              placeholder={t("CreateContentPopup.placeholders.description")}
            />
          </div>
          {contentType.value !== "Tip" && (
            <div className="create-input content-link-container">
              <Textarea
                type="text"
                value={contentLink}
                width="265px"
                onChange={(e) => {
                  setContentLink(e.target.value);
                }}
                placeholder={t("CreateContentPopup.placeholders.link")}
              />
            </div>
          )}
          {errors.includes("no-link") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.no-link")}
            </div>
          )}
          {errors.includes("invalid-link") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.invalid-link")}
            </div>
          )}
          <div className="create-input select-content-countries-container">
            <Select
              style={{ width: "265px" }}
              name={"contentCountries"}
              value={contentCountries}
              isMulti
              onChange={(e) => {
                let options = e.map((option) =>
                  option.label.replace(/,/g, " ")
                );
                setContentCountriesCodes(
                  countriesOptions.filter((x) => options.includes(x.label))
                );
                setContentCountries(
                  options.filter((option) => !isEmptyOrSpaces(option))
                );
                let difference = countriesOptions.filter(
                  (x) => !options.includes(x.label)
                );
                setFilteredCountriesOptions(difference);
                setAllContentCountries(false);
              }}
              options={filteredCountriesOptions}
              placeholder={t("CreateContentPopup.placeholders.countries")}
              ref={null}
              searchable={true}
            />
            <button
              className="font-button"
              onClick={() => {
                selectAllCountries();
              }}
            >
              {t("CreateContentPopup.select-all")}
            </button>
          </div>
          {errors.includes("no-countries") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.countries")}
            </div>
          )}
          <div className="create-input select-content-categories-container">
            <Select
              style={{ width: "265px" }}
              name={"contentCategories"}
              value={contentCategories}
              isMulti
              onChange={(e) => {
                let options = e.map((option) =>
                  option.label.replace(/,/g, " ")
                );
                setContentCategoriesId(
                  categoriesOptions.filter((x) => options.includes(x.label))
                );
                setContentCategories(
                  options.filter((option) => !isEmptyOrSpaces(option))
                );
                const difference = categoriesOptions.filter(
                  (x) => !options.includes(x.label)
                );
                setFilteredCategoriesOptions(difference);
                setAllContentCategories(false);
              }}
              options={filteredCategoriesOptions}
              placeholder={t("CreateContentPopup.placeholders.categories")}
              ref={null}
              searchable={true}
            />

            <button
              className="font-button"
              onClick={() => {
                selectAllCategories();
              }}
            >
              {t("CreateContentPopup.select-all")}
            </button>
          </div>
          {errors.includes("no-categories") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.categories")}
            </div>
          )}
          <div className="create-input select-content-tags-container">
            <Select
              style={{ width: "265px" }}
              name={"contentTags"}
              value={contentTags}
              isMulti
              onChange={(e) => {
                let options = e.map((option) =>
                  option.label.replace(/,/g, " ")
                );
                setContentTagsValues(
                  tagsOptions.filter((x) => options.includes(x.label))
                );
                setContentTags(
                  options.filter((option) => !isEmptyOrSpaces(option))
                );
                const difference = tagsOptions.filter(
                  (x) => !options.includes(x.label)
                );
                setFilteredTagsOptions(difference);
                setAllContentCategories(false);
              }}
              options={filteredTagsOptions}
              placeholder={t("CreateContentPopup.placeholders.tags")}
              ref={null}
              searchable={true}
            />
            <button
              className="font-button"
              onClick={() => {
                selectAllTags();
              }}
            >
              {t("CreateContentPopup.select-all")}
            </button>
          </div>
          {errors.includes("no-tags") && (
            <div className="error-container">
              {t("CreateContentPopup.errors.tags")}
            </div>
          )}
          <div className="create-input select-content-languages-container">
            <Select
              style={{ width: "265px" }}
              name={"contentLanguages"}
              value={contentLanguages}
              isMulti
              onChange={(e) => {
                let options = e.map((option) =>
                  option.label.replace(/,/g, " ")
                );
                setContentLanguagesValues(
                  languagesOptions.filter((x) => options.includes(x.label))
                );
                setContentLanguages(
                  options.filter((option) => !isEmptyOrSpaces(option))
                );
                const difference = languagesOptions.filter(
                  (x) => !options.includes(x.label)
                );
                setFilteredLanguagesOptions(difference);
                setAllContentLanguages(false);
              }}
              options={filteredLanguagesOptions}
              placeholder={t("CreateContentPopup.placeholders.languages")}
              ref={null}
              searchable={true}
            />
            <button
              className="font-button"
              onClick={() => {
                selectAllLanguages();
              }}
            >
              {t("CreateContentPopup.select-all")}
            </button>
          </div>
          {errors.includes("no-languages") && (
            <div className="error-container">{"no language"}</div>
          )}
          <div className="create-input content-image-container">
            <input
              type="file"
              accept="image/*"
              name="image"
              className="hidden-file-input"
              ref={uploadFromDeviceImageRef}
              onChange={(e) => changeImage(e)}
            />
            <button
              className="font-button"
              onClick={() => {
                if (window.ReactNativeWebView) {
                  if (cameraAndMediaLibraryPermissionGranted) {
                    uploadFromDeviceImageRef.current.click();
                  } else if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        event:
                          NativeEvents.RequestCameraAndMediaLibraryPermissions,
                      })
                    );
                  }
                } else {
                  uploadFromDeviceImageRef.current.click();
                }
              }}
            >
              <FontAwesomeIcon icon={faUpload} />
              &nbsp;{"File"}
            </button>
            {/* {contentImage !==
              "https://tlechaim-static.fra1.digitaloceanspaces.com/gallery-images/MediaCenter/content-default.png" && ( */}
            {contentImage && (
              <button
                className="font-button"
                onClick={() => {
                  setContentImage(
                    ""
                    // "https://tlechaim-static.fra1.digitaloceanspaces.com/gallery-images/MediaCenter/content-default.png"
                  );
                }}
              >
                {t("CreateContentPopup.remove-image")}
              </button>
            )}
          </div>
        </div>
        <div className="create-input avatar-container flex">
          <AvatarEditor
            onClick={() => {}}
            crossOrigin="anonymous"
            image={contentImage}
            width={265}
            height={265}
            borderRadius={150}
            color={[241, 241, 241, 0.6]} // RGBA
            scale={scale}
            rotate={0}
            ref={processedImageRef}
          />
          <input
            type="range"
            step="0.001"
            min="0.5"
            max="3"
            onChange={(el) => setScale(Number(el.target.value))}
            value={scale}
          />
        </div>
        {errors.includes("no-image") && (
          <div className="error-container">
            {t("CreateContentPopup.errors.image")}
          </div>
        )}
        <div className="create-input save-container">
          <PopupButton
            className={"confirm-edit-content-btn"}
            onClick={() => {
              let errorOccurred = false;
              if (!contentLocation.value) {
                setErrors((prevState) => [...prevState, "no-location"]);
                errorOccurred = true;
              }
              if (contentType === "Video" && !contentVideoType.value) {
                setErrors((prevState) => [...prevState, "no-video-type"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-video-type")
                );
              }
              if (!contentTitle) {
                setErrors((prevState) => [...prevState, "no-title"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-title")
                );
              }
              if (!contentLink && contentType.value !== "Tip") {
                setErrors((prevState) => [...prevState, "no-link"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-link")
                );
              }
              if (!contentCountries || contentCountries.length === 0) {
                setErrors((prevState) => [...prevState, "no-countries"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-countries")
                );
              }
              if (!contentCategories || contentCategories.length === 0) {
                setErrors((prevState) => [...prevState, "no-categories"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-categories")
                );
              }
              if (!contentTags || contentTags.length === 0) {
                setErrors((prevState) => [...prevState, "no-tags"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-tags")
                );
              }
              if (!contentLanguages || contentLanguages.length === 0) {
                setErrors((prevState) => [...prevState, "no-languages"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "no-languages")
                );
              }
              // if (!contentImage) {
              //   setErrors((prevState) => [...prevState, "no-image"]);
              //   errorOccurred = true;
              // } else {
              //   setErrors((prevState) =>
              //     prevState.filter((item) => item !== "no-image")
              //   );
              // }
              if (!checkViableLink()) {
                setErrors((prevState) => [...prevState, "invalid-link"]);
                errorOccurred = true;
              } else {
                setErrors((prevState) =>
                  prevState.filter((item) => item !== "invalid-link")
                );
              }
              if (errorOccurred) {
                return;
              }

              const data = {
                Id: contentId,
                VideoType: contentVideoType.value,
                Title: contentTitle,
                Description: contentDescription,
                Link: contentLink,
                Image: contentImage,
                Countries: {
                  All: allContentCountries,
                  CountriesCode: contentCountriesCodes.map(
                    (country) => country.value
                  ),
                },
                Categories: {
                  All: allContentCategories,
                  CategoriesId: contentCategoriesId.map(
                    (category) => category.value
                  ),
                },
                Tags: {
                  All: allContentTags,
                  Tags: contentTagsValues.map((tag) => tag.value),
                },
                Languages: {
                  All: allContentLanguages,
                  Languages: contentLanguagesValues.map((lang) => lang.value),
                },
                Location: contentLocation.value,
              };
              const saveBtn = document.getElementsByClassName(
                "confirm-edit-content-btn"
              )[0];
              try {
                dispatch(showToastMessage({ loading: true }));
                saveBtn.disabled = true;
                dispatch(editContent(data)).then(async (res) => {
                  if (!contentImage) {
                    // remove existing profile image
                    if (
                      contentImage !==
                      "https://tlechaim-static.fra1.digitaloceanspaces.com/gallery-images/MediaCenter/content-default.png"
                    ) {
                      deleteContentImage(res.meta.arg.Image);
                    }
                  } else {
                    const data = res.payload;
                    const formDataValues = new FormData();

                    for (const key in data.fields)
                      formDataValues.append(key, data.fields[key]);
                    const imagePreview = processedImageRef.current
                      .getImageScaledToCanvas()
                      .toDataURL();

                    const blob = await new Promise((resolve) => {
                      fetch(imagePreview)
                        .then((res) => res.blob())
                        .then((blob) => resolve(blob));
                    });
                    formDataValues.append("Content-type", "image/png");
                    formDataValues.append("file", blob);

                    await axios.post(data.url, formDataValues);
                  }

                  dispatch(getContents()).then((res) => {
                    saveBtn.disabled = false;
                    dispatch(
                      showToastMessage({
                        loading: false,
                        text: t("edit-success"),
                        type: toast.TYPE.SUCCESS,
                      })
                    );
                    props.closePopup(res.payload, true);
                  });
                  setContentTitle("");
                  setContentDescription("");
                  setContentLink("");
                  setContentCountries([]);
                  setContentCategories([]);
                  setContentTags([]);
                  setFilteredCountriesOptions([]);
                  setErrors([]);
                });
              } catch (error) {
                saveBtn.disabled = false;
                dispatch(
                  showToastMessage({
                    loading: false,
                    text: t("Common:error-occurred"),
                    type: toast.TYPE.ERROR,
                  })
                );
                console.log(error);
              }
            }}
          >
            {t("Common:save")}
          </PopupButton>
        </div>
      </Popup>
    </div>
  );
};
