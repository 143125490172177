import React from "react";
import "./IframeContentFullScreen.scss";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShowIframeView } from "../../store/app-settings/app-settings-slice";
export default function IframeContentFullScreen({ src }) {
  const profile = useSelector((state) => state.profileReducer.profile);
  const dispatch = useDispatch();
  const closeIframeHandler = () => {
    dispatch(setShowIframeView(""));
  };
  return (
    <div className="IframeContentFullScreen">
      <iframe
        style={{ border: "none" }}
        allowFullScreen
        allow="fullscreen"
        src={src}
      ></iframe>
      <button
        className={`${profile?.Settings?.LanguageCode === "he" ? "rtl" : ""}`}
        onClick={closeIframeHandler}
      >
        <XIcon />
      </button>
    </div>
  );
}
