import React, { useState } from "react";
import "./IframeContent.scss";
import { useDispatch } from "react-redux";
import { setShowIframeView } from "../../store/app-settings/app-settings-slice";
import { useTranslation } from "react-i18next";
export default function IframeContent({ src }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const fullScreenHandler = () => {
    dispatch(setShowIframeView(src));
  };
  return (
    <div className="IframeContent">
      <iframe
        style={{ border: "none" }}
        allowFullScreen
        allow="fullscreen"
        src={src}
      ></iframe>
      <button onClick={fullScreenHandler}>{t("Common:full_screen")}</button>
    </div>
  );
}
