import React from "react";
import "./user-content-box.scss";
import { useState } from "react";
import { ViewVideoModalMobile } from "./view-video-modal/view-video-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as BoosterIcon } from "../../../../assets/icons/booster_2.svg";
import shareIcon from "../../../../assets/images/share-media.jpg";
import AddToBoostersIcon from "../../../../assets/images/not-in-boosters-icon.png";
import OnBoostersIcon from "../../../../assets/images/save-as-booster.png";
import ArticleIcon from "../../../../assets/images/article-icon.png";
import VideoIcon from "../../../../assets/images/play-icon.png";
import PodcastIcon from "../../../../assets/images/podcast-icon.png";
import SongIcon from "../../../../assets/images/song-icon.png";
import TipIcon from "../../../../assets/images/tip-icon.png";
import OtherIcon from "../../../../assets/images/other-icon.png";
import { ReactComponent as XIcon } from "../../../../assets/icons/x.svg";

import { useTranslation } from "react-i18next";
import { ShareMedia } from "../share-media-popup/share-media-popup";
import { ViewTipModal } from "./view-tip-modal/view-tip-modal";
import { useSelector } from "react-redux";

export const UserContentBox = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  const { direction } = useSelector((state) => ({
    direction: state.appSettingsReducer.direction,
  }));

  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const [showTipPopup, setShowTipPopup] = useState(false);
  const [showSharePopup, setShowSharePopup] = useState(false);

  const getIcon = () => {
    if (props.item.Type === "Article") {
      return <img className="media-type-icon" src={ArticleIcon} />;
    } else if (props.item.Type === "Podcast") {
      return <img className="media-type-icon" src={PodcastIcon} />;
    } else if (props.item.Type === "Song") {
      return <img className="media-type-icon" src={SongIcon} />;
    } else if (props.item.Type === "Tip") {
      return <img className="media-type-icon" src={TipIcon} />;
    } else if (props.item.Type === "Other") {
      return <img className="media-type-icon" src={OtherIcon} />;
    }
  };

  const getIconNoImage = () => {
    if (props.item.Type === "Article") {
      return <img className="media-type-icon-no-image" src={ArticleIcon} />;
    } else if (props.item.Type === "Video") {
      return <img className="media-type-icon-no-image" src={VideoIcon} />;
    } else if (props.item.Type === "Podcast") {
      return (
        <img className="media-type-icon-no-image-long" src={PodcastIcon} />
      );
    } else if (props.item.Type === "Song") {
      return <img className="media-type-icon-no-image" src={SongIcon} />;
    } else if (props.item.Type === "Tip") {
      return <img className="media-type-icon-no-image-long" src={TipIcon} />;
    } else if (props.item.Type === "Other") {
      return <img className="media-type-icon-no-image" src={OtherIcon} />;
    }
  };

  const isHebrewOrArabic = () => {
    if (props.item.Title.length === 0) {
      return false;
    }
    const firstLetter = props.item.Title[0];
    const charCode = firstLetter.charCodeAt(0);
    return (
      (charCode >= 0x0591 && charCode <= 0x05f4) || // Hebrew
      (charCode >= 0x0600 && charCode <= 0x06ff) || // Arabic
      (charCode >= 0xfb50 && charCode <= 0xfdff) || // Arabic Presentation Forms-A
      (charCode >= 0xfe70 && charCode <= 0xfeff)
    );
  };

  return (
    <div>
      {showVideoPopup && (
        <ViewVideoModalMobile
          width="350px"
          height="400px"
          showPopup={showVideoPopup}
          closePopup={() => {
            setShowVideoPopup(false);
          }}
          item={props.item}
          id={props.id}
          bookmarkUserContent={props.bookmarkUserContent}
          bookmarked={props.bookmarked}
          setShowSharePopup={setShowSharePopup}
        />
      )}
      {showTipPopup && (
        <ViewTipModal
          width="350px"
          height="400px"
          showPopup={showTipPopup}
          closePopup={() => {
            setShowTipPopup(false);
          }}
          item={props.item}
          id={props.id}
          bookmarkUserContent={props.bookmarkUserContent}
          bookmarked={props.bookmarked}
          setShowSharePopup={setShowSharePopup}
        />
      )}
      {showSharePopup && (
        <ShareMedia
          item={props.item}
          close={() => {
            setShowSharePopup(false);
          }}
        />
      )}
      <div className="user-content-box-container" id={props.id}>
        <div
          className="image-container"
          onClick={() => {
            if (props.item) {
              if (
                props.item.VideoType === "YouTube" ||
                props.item.VideoType === "Vimeo" ||
                props.item.VideoType === "Iframe"
              ) {
                setShowVideoPopup(true);
              } else {
                setShowTipPopup(true);
              }
              // if (
              //   props.item.Type !== "Tip" &&
              //   (!props.item.VideoType ||
              //     props.item.VideoType === "Not video" ||
              //     props.item.VideoType === "notVideo")
              // ) {
              //   window.open(props.item.Link, "_blank");
              // } else {
              //   if (props.item.Type !== "Tip") {
              //     setShowVideoPopup(true);
              //   } else {
              //     setShowTipPopup(true);
              //   }
              // }
              props.viewUserContent(props.item, props.id);
            }
          }}
        >
          {props.item.Image ? (
            <div>
              {props.item.Type !== "Video" ? (
                <div className="type-icon">{getIcon()}</div>
              ) : (
                <div className="video-icon-container">
                  <img className="video-type-icon" src={VideoIcon} />
                </div>
              )}
              <img
                className="selected-image"
                src={
                  props.item
                    ? process.env.REACT_APP_BASE_IMAGE_URL +
                      "/" +
                      props.item.Image
                    : ""
                }
              ></img>
            </div>
          ) : (
            <div
              className="type-no-image"
              onClick={() => {
                if (props.item) {
                  if (
                    props.item.VideoType === "YouTube" ||
                    props.item.VideoType === "Vimeo" ||
                    props.item.VideoType === "Iframe"
                  ) {
                    setShowVideoPopup(true);
                  } else {
                    setShowTipPopup(true);
                  }
                  // if (
                  //   props.item.Type !== "Tip" &&
                  //   (!props.item.VideoType ||
                  //     props.item.VideoType === "Not video" ||
                  //     props.item.VideoType === "notVideo")
                  // ) {
                  //   window.open(props.item.Link, "_blank");
                  // } else {
                  //   if (props.item.Type !== "Tip") {
                  //     setShowVideoPopup(true);
                  //   } else {
                  //     setShowTipPopup(true);
                  //   }
                  // }
                  props.viewUserContent(props.item, props.id);
                }
              }}
            >
              <div>{getIconNoImage()}</div>
            </div>
          )}
        </div>
        <div
          className="info-container"
          style={
            direction === "rtl" && isHebrewOrArabic()
              ? {
                  direction: "rtl",
                  textAlign: "right",
                }
              : direction === "ltr" && isHebrewOrArabic()
              ? {
                  direction: "rtl",
                  textAlign: "right",
                  transform: "translateX(0px)",
                }
              : direction === "ltr" && !isHebrewOrArabic()
              ? {}
              : {
                  direction: "ltr",
                  textAlign: "left",
                  transform: "translateX(0px)",
                }
          }
        >
          <div className="content-date">
            <h6>
              {props.item ? props.item.CreationDate.replaceAll("/", ".") : ""}
            </h6>
          </div>
          <div
            className="content-title"
            onClick={() => {
              if (props.item) {
                if (
                  props.item.VideoType === "YouTube" ||
                  props.item.VideoType === "Vimeo" ||
                  props.item.VideoType === "Iframe"
                ) {
                  setShowVideoPopup(true);
                } else {
                  setShowTipPopup(true);
                }
                // if (
                //   props.item.Type !== "Tip" &&
                //   (!props.item.VideoType ||
                //     props.item.VideoType === "Not video" ||
                //     props.item.VideoType === "notVideo")
                // ) {
                //   window.open(props.item.Link, "_blank");
                // } else {
                //   if (props.item.Type !== "Tip") {
                //     setShowVideoPopup(true);
                //   } else {
                //     setShowTipPopup(true);
                //   }
                // }
                props.viewUserContent(props.item, props.id);
              }
            }}
          >
            <h5>
              {props.item
                ? props.item.Title.length < 50
                  ? props.item.Title
                  : props.item.Title.substring(0, 47) + "..."
                : ""}
            </h5>
          </div>
          <div className="content-desription">
            <h6>
              {props.item
                ? props.item.Description.length < 65
                  ? props.item.Description
                  : props.item.Description.substring(0, 62) + "..."
                : ""}
            </h6>
          </div>
        </div>

        <div
          className="actions-container"
          style={
            direction === "rtl" && isHebrewOrArabic()
              ? {
                  direction: "rtl",
                  textAlign: "right",
                }
              : direction === "ltr" && isHebrewOrArabic()
              ? { direction: "rtl", textAlign: "right", right: "30px" }
              : direction === "ltr" && !isHebrewOrArabic()
              ? {}
              : {
                  direction: "ltr",
                  textAlign: "left",
                  left: "30px",
                }
          }
        >
          {props.showOnlyBookmarked ? (
            <XIcon
              style={{ width: "30px", height: "30px" }}
              onClick={() => {
                props.bookmarkUserContent(props.item, props.id);
              }}
            />
          ) : props.bookmarked ? (
            <div className="media-icon-container">
              <img
                className="media-icon"
                src={OnBoostersIcon}
                onClick={() => {
                  props.bookmarkUserContent(props.item, props.id);
                }}
              />
            </div>
          ) : (
            <div
              className="media-bookmark-action-icon-container"
              onClick={() => {
                props.bookmarkUserContent(props.item, props.id);
              }}
            >
              <img className="media-icon" src={AddToBoostersIcon} />
            </div>
          )}
          <img
            className="media-icon"
            src={shareIcon}
            onClick={() => {
              setShowSharePopup(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
