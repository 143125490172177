import { useState, useEffect } from "react";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import YouTubeVideo from "../../../../components/youtube-content/youtube-content";
import "./view-video-modal.scss";
import { useTranslation } from "react-i18next";
import VimeoVideo from "../../../../components/vimeo-content/vimeo-content";
import IframeContent from "../../../../components/iframe-content/IframeContent";

export const ViewVideoModal = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");
  useEffect(() => {}, []);
  return (
    <div className="view-content-video-container flex-popup-container">
      <Popup
        height={props.height ? props.height : "650px"}
        width={props.width ? props.width : "1000px"}
        close={() => {
          props.closePopup();
        }}
      >
        <div className="view-video-title">
          <PopupTitle>{t("video-popup-title")}</PopupTitle>
        </div>
        <div className="video-conatiner content-country-container">
          {props.item.VideoType === "YouTube" ? (
            <YouTubeVideo fullScreen={false} url={props.item.Link} />
          ) : props.item.VideoType === "Iframe" ? (
            <IframeContent src={props.item.Link} />
          ) : (
            <VimeoVideo fullScreen={false} url={props.item.Link} />
          )}
        </div>
      </Popup>
    </div>
  );
};
