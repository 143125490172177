import React from "react";
import { Popup } from "../../../../components/popup/popup";
import { PopupTitle } from "../../../../components/popup/popup-title/popup-title";
import { PopupButton } from "../../../../components/popup-button/popup-button";
import ActorImage from "../../../../assets/images/actor_with_book.png";
import XSvg from "../../../../assets/icons/x.svg";
import "./HopeJourneyGuidePopup.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function HopeJourneyGuidePopup({ close }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Popup>
      <div className="HopeJourneyGuidePopup">
        <img
          onClick={() => close()}
          className="close_btn"
          src={XSvg}
          alt="x svg"
        />
        <PopupTitle>{t("PopUps:HopeJourneyGuide.title")}</PopupTitle>
        <div className="text_container">
          <p>{t("PopUps:HopeJourneyGuide.text1")}</p>
          <p>{t("PopUps:HopeJourneyGuide.text2")}</p>
          <p>{t("PopUps:HopeJourneyGuide.text3")}</p>
          <strong>{t("PopUps:HopeJourneyGuide.textBold")}</strong>
        </div>
        <img className="actor_image" src={ActorImage} alt="actor image" />
        <PopupButton
          onClick={() => {
            navigate("/Courses");
            close();
          }}
        >
          {t("PopUps:HopeJourneyGuide.button")}
        </PopupButton>
      </div>
    </Popup>
  );
}
