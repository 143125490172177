import { useState, useEffect } from "react";
import { Popup } from "../../../../../components/popup/popup";
import { PopupTitle } from "../../../../../components/popup/popup-title/popup-title";
import "./view-video-modal.scss";
import { useTranslation } from "react-i18next";
import YouTubeVideo from "../../../../../components/youtube-content/youtube-content";
import VimeoVideo from "../../../../../components/vimeo-content/vimeo-content";
// import VimeoVideo from "../../../../components/vimeo-content/vimeo-content";
import BoosterIcon from "../../../../../assets/images/save-as-booster.png";
import AddToBoostersIcon from "../../../../../assets/images/not-in-boosters-icon.png";
import ShareIcon from "../../../../../assets/images/share-media.jpg";
import { ShareMedia } from "../../share-media-popup/share-media-popup";
import IframeContent from "../../../../../components/iframe-content/IframeContent";

export const ViewVideoModalMobile = (props) => {
  const { t, i18n } = useTranslation("MediaCenter");

  useEffect(() => {}, []);
  return (
    <div className="view-content-video-container flex-popup-container">
      <Popup
        height={props.height ? props.height : "650px"}
        width={props.width ? props.width : "1000px"}
        close={() => {
          props.closePopup();
        }}
      >
        <div className="video-conatiner content-country-container">
          {props.item.VideoType === "YouTube" ? (
            <YouTubeVideo
              style={{
                width: "300px",
                marginLeft: "20px",
                height: "100px",
                minHeight: "200px",
                height: "200px",
              }}
              fullScreen={false}
              url={props.item.Link}
            />
          ) : props.item.VideoType === "Iframe" ? (
            <IframeContent src={props.item.Link} />
          ) : (
            <VimeoVideo fullScreen={false} url={props.item.Link} />
          )}
        </div>
        <div className="info-container">
          <div className="content-date">
            <h5>
              {props.item ? props.item.CreationDate.replaceAll("/", ".") : ""}
            </h5>
          </div>
          <div className="content-title">
            <h4>{props.item ? props.item.Title : ""}</h4>
          </div>
          <div className="content-desription">
            <h5>{props.item ? props.item.Description : ""}</h5>{" "}
          </div>
          {props.bookmarked ? (
            <img
              src={BoosterIcon}
              className="booster-icon"
              onClick={() => {
                props.bookmarkUserContent(props.item, props.id);
                props.closePopup();
              }}
            />
          ) : (
            <img
              src={AddToBoostersIcon}
              className="booster-icon"
              onClick={() => {
                props.bookmarkUserContent(props.item, props.id);
                props.closePopup();
              }}
            />
          )}
          <img
            src={ShareIcon}
            className="share-icon"
            onClick={() => {
              props.closePopup();
              props.setShowSharePopup(true);
            }}
          />
        </div>
      </Popup>
    </div>
  );
};
